var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('general-table',{attrs:{"api-url":_vm.APIURL,"add-type":_vm.addType,"delete-content":true,"block-content":false,"edit-content":true,"view-content":false,"selectable":false,"columns":_vm.filteredColumns,"type":_vm.type,"per-page":_vm.perPage,"add-component-name":_vm.addComponentName,"selectcertificate":true,"certificate-options":_vm.certificate,"sub-certificate-options":_vm.subCertificate,"select-subcertificate":true},scopedSlots:_vm._u([{key:"cell(email_sended)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[(data.item.email_sended === 1)?_c('span',[_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"25","color":"#02111b"}})],1):_c('span',[_c('feather-icon',{attrs:{"icon":"XIcon","size":"25","color":"red"}})],1)])]},null,data)]}},{key:"cell(require_audit)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[(data.item.require_audit === 1)?_c('span',[_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"25","color":"#02111b"}})],1):_c('span',[_c('feather-icon',{attrs:{"icon":"XIcon","size":"25","color":"red"}})],1)])]},null,data)]}},{key:"cell(invoice)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[_c('span',[_c('feather-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('show invoice'),expression:"'show invoice'",modifiers:{"hover":true,"topright":true}}],staticStyle:{"cursor":"pointer"},attrs:{"icon":"FileTextIcon","size":"25","color":"#02111b"},on:{"click":function($event){return _vm.$router.push({
                  name: 'show-invoice',
                  params: { id: data.item.id }
                })}}})],1)])]},null,data)]}},{key:"cell(certificate)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[_c('span',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap"}},[_vm._v(" "+_vm._s(data.item.certificate)+" ")])])]},null,data)]}},{key:"cell(sub_certificate)",fn:function(data){return [(_vm.role === 'super-accountant' || _vm.role === 'accountant')?_c('div',[_c('span',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap"}},[_vm._v(" "+_vm._s(data.item.sub_certificate)+" ")])]):_c('div',[(data.item.sub_certificate === null)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('edit sub certificate'),expression:"'edit sub certificate'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer-empty text-center",staticStyle:{"width":"100% !important","height":"24px !important"},on:{"click":function($event){return _vm.$router.push({
              name: 'edit-sub-certificate',
              params: { id: data.item.id }
            })}}}):_vm._e(),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('edit sub certificate'),expression:"'edit sub certificate'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer text-center",staticStyle:{"height":"100% !important","display":"flex","align-items":"center","justify-content":"center"},on:{"click":function($event){return _vm.$router.push({
              name: 'edit-sub-certificate',
              params: { id: data.item.id }
            })}}},[_c('span',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap"}},[_vm._v(" "+_vm._s(data.item.sub_certificate)+" ")])])])]}},{key:"cell(facility_name)",fn:function(data){return [(data.item.facility_name === '')?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('show facility info'),expression:"'show facility info'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer-empty text-center",staticStyle:{"width":"100% !important","height":"24px !important"},on:{"click":function($event){return _vm.$router.push({
            name: 'request-histories',
            params: { id: data.item.id }
          })}}}):_vm._e(),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('show facility info'),expression:"'show facility info'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer text-center ",staticStyle:{"height":"100% !important","display":"flex","align-items":"center","justify-content":"center"},on:{"click":function($event){return _vm.$router.push({
            name: 'request-histories',
            params: { id: data.item.id }
          })}}},[_c('span',{staticClass:"text-nowrap",staticStyle:{"font-size":"smaller"}},[_vm._v(" "+_vm._s(data.item.facility_name)+" ")])])]}},{key:"cell(categoryRequestNo)",fn:function(data){return [_c('span',{staticClass:"text-nowrap px-1",staticStyle:{"font-size":"smaller"}},[_vm._v(" "+_vm._s(data.item.categoryRequestNo)+" ")])]}},{key:"cell(last_status_update)",fn:function(data){return [_c('span',{staticClass:"text-nowrap px-1",staticStyle:{"font-size":"smaller"}},[_vm._v(" "+_vm._s(data.item.last_status_update)+" ")])]}},{key:"cell(payment_status)",fn:function(data){return [(_vm.role === 'process-manager')?_c('div',[_c('span',{staticClass:"text-nowrap",staticStyle:{"font-size":"smaller"}},[_vm._v(" "+_vm._s(data.item.payment_status)+" ")])]):_c('div',[_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add payment'),expression:"'+ add payment'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer text-center ",staticStyle:{"height":"100% !important","display":"flex","align-items":"center","justify-content":"center"},on:{"click":function($event){return _vm.$router.push({
              name: 'edit-payment-status',
              params: { id: data.item.id }
            })}}},[_c('span',{staticClass:"text-nowrap",staticStyle:{"font-size":"smaller"}},[_vm._v(" "+_vm._s(data.item.payment_status)+" ")])])])]}},{key:"cell(request_code)",fn:function(data){return [_c('a',{staticClass:"request-code-a",attrs:{"href":("https://engineers.gulftic.com/orders/order/" + (data.item.id) + "?role=" + _vm.accessRole + "&name=" + _vm.accessName + "&email=" + _vm.accessEmail + "&token=" + _vm.accessToken),"target":"_blank"}},[_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('Go To Request'),expression:"'Go To Request'",modifiers:{"hover":true,"topright":true}}]},[_c('span',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap"}},[_vm._v(" "+_vm._s(data.item.request_code)+" ")])])])]}},{key:"cell(offer)",fn:function(data){return [(data.item.offer === null)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add offer'),expression:"'+ add offer'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer-empty text-center",staticStyle:{"width":"100% !important","height":"24px !important","font-size":"smaller"},on:{"click":function($event){return _vm.$router.push({
            name: 'set-offer',
            params: { id: data.item.id }
          })}}}):_vm._e(),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add offer'),expression:"'+ add offer'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer text-center",staticStyle:{"height":"100% !important","display":"flex","align-items":"center","justify-content":"center","font-size":"smaller"},on:{"click":function($event){return _vm.$router.push({
            name: 'set-offer',
            params: { id: data.item.id }
          })}}},[(data.item.offer)?_c('span',[_vm._v(" "+_vm._s(data.item.offer.name)+" - "+_vm._s(data.item.offer.amount)+" % ")]):_vm._e()])]}},{key:"cell(office)",fn:function(data){return [(data.item.office === null)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add office'),expression:"'+ add office'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer-empty text-center",staticStyle:{"width":"100% !important","height":"24px !important","font-size":"smaller"},on:{"click":function($event){return _vm.$router.push({
            name: 'set-office',
            params: { id: data.item.id }
          })}}}):_vm._e(),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add office'),expression:"'+ add office'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer text-center",staticStyle:{"height":"100% !important","display":"flex","align-items":"center","justify-content":"center","font-size":"smaller"},on:{"click":function($event){return _vm.$router.push({
            name: 'set-office',
            params: { id: data.item.id }
          })}}},[(data.item.office)?_c('span',[_vm._v(" "+_vm._s(data.item.office)+" ")]):_vm._e()])]}},{key:"cell(first_reviewer)",fn:function(data){return [(data.item.first_reviewer === null)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add first reviewer'),expression:"'+ add first reviewer'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer-empty text-center",staticStyle:{"width":"100% !important","height":"24px !important"},on:{"click":function($event){return _vm.$router.push({
            name: 'assign-engineer',
            params: { id: data.item.id, type: 'first_reviewer' }
          })}}}):_vm._e(),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add first reviewer'),expression:"'+ add first reviewer'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer text-center",staticStyle:{"height":"100% !important","display":"flex","align-items":"center","justify-content":"center"},on:{"click":function($event){return _vm.$router.push({
            name: 'assign-engineer',
            params: { id: data.item.id, type: 'first_reviewer' }
          })}}},[_c('span',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap"}},[_vm._v(" "+_vm._s(data.item.first_reviewer)+" ")])])]}},{key:"cell(second_reviewer)",fn:function(data){return [(data.item.second_reviewer === null)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add second reviewer'),expression:"'+ add second reviewer'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer-empty text-center",staticStyle:{"width":"100% !important","height":"24px !important"},on:{"click":function($event){return _vm.$router.push({
            name: 'assign-engineer',
            params: { id: data.item.id, type: 'second_reviewer' }
          })}}}):_vm._e(),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add second reviewer'),expression:"'+ add second reviewer'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer text-center",staticStyle:{"height":"100% !important","display":"flex","align-items":"center","justify-content":"center"},on:{"click":function($event){return _vm.$router.push({
            name: 'assign-engineer',
            params: { id: data.item.id, type: 'second_reviewer' }
          })}}},[_c('span',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap"}},[_vm._v(" "+_vm._s(data.item.second_reviewer)+" ")])])]}},{key:"cell(application_reviewer)",fn:function(data){return [(data.item.application_reviewer === null)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add application reviewer'),expression:"'+ add application reviewer'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer-empty text-center",staticStyle:{"width":"100% !important","height":"24px !important"},on:{"click":function($event){return _vm.$router.push({
            name: 'assign-engineer',
            params: { id: data.item.id, type: 'application_reviewer' }
          })}}}):_vm._e(),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add application reviewer'),expression:"'+ add application reviewer'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer text-center",staticStyle:{"height":"100% !important","display":"flex","align-items":"center","justify-content":"center"},on:{"click":function($event){return _vm.$router.push({
            name: 'assign-engineer',
            params: { id: data.item.id, type: 'application_reviewer' }
          })}}},[_c('span',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap"}},[_vm._v(" "+_vm._s(data.item.application_reviewer)+" ")])])]}},{key:"cell(requester)",fn:function(data){return [(data.item.delegator)?_c('span',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap"}},[_vm._v(" "+_vm._s(data.item.delegator.name)+" ")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }